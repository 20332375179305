// extracted by mini-css-extract-plugin
export var defaultWrapper = "_9j-3";
export var downloadButton = "_9j-4";
export var fileContainer = "_9j-7";
export var fileInfo = "_9j-5";
export var fileInfoWidthRestricted = "_9j-6";
export var fileType = "_9j-1";
export var fileTypeName = "_9j-2";
export var image = "_9j-0";
export var link = "_9j-9";
export var overflowEllipsis = "_9j-8";