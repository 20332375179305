// extracted by mini-css-extract-plugin
export var bubbleContent = "_br-0";
export var dark = "_br-2";
export var dot = "_br-7";
export var firstDot = "_br-9";
export var left = "_br-3";
export var light = "_br-1";
export var loadingBubble = "_br-5";
export var loadingDot = "_br-c";
export var loadingDotContainer = "_br-6";
export var moveUpDown = "_br-8";
export var right = "_br-4";
export var secondDot = "_br-a";
export var thirdDot = "_br-b";