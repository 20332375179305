// extracted by mini-css-extract-plugin
export var active = "_s-d";
export var blink = "_s-e";
export var container = "_s-5";
export var controls = "_s-0";
export var controlsRight = "_s-1";
export var fakeCursor = "_s-c";
export var hidden = "_s-b";
export var quillContainerSmall = "_s-7";
export var quillEditor = "_s-6";
export var quillEditorSmall = "_s-8";
export var quillFormatButtons = "_s-a";
export var quillToolbar = "_s-9";
export var removePadding = "_s-2";
export var state = "_s-3";
export var stateTitle = "_s-4";