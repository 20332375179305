// extracted by mini-css-extract-plugin
export var chevron = "_4g-b";
export var disabled = "_4g-a";
export var hideBorder = "_4g-1";
export var icon = "_4g-c";
export var input = "_4g-0";
export var open = "_4g-8";
export var placeholder = "_4g-9";
export var regular = "_4g-4";
export var singleSelectInput = "_4g-2 _4g-0";
export var small = "_4g-3";
export var spinnerContainer = "_4g-e";
export var tag = "_4g-6";
export var tagsContainer = "_4g-7";
export var tagsInput = "_4g-5 _4g-0";
export var xSmall = "_4g-d";