// extracted by mini-css-extract-plugin
export var actionButton = "_ac-t";
export var buttonContainer = "_ac-0";
export var buttonContainer2 = "_ac-1";
export var buttonSection = "_ac-2";
export var details = "_ac-3";
export var dropdownItem = "_ac-5";
export var dropdownItemsContainer = "_ac-4";
export var fileInput = "_ac-r";
export var headerButton = "_ac-6";
export var icon = "_ac-7";
export var input = "_ac-8";
export var macroListItem = "_ac-9";
export var mainContent = "_ac-a";
export var microText = "_ac-b";
export var modal = "_ac-w";
export var optionsButton = "_ac-c";
export var paperclip = "_ac-s";
export var pillContent = "_ac-d";
export var preview = "_ac-e";
export var preview2 = "_ac-f";
export var quillEditor = "_ac-q";
export var selectedMacro = "_ac-g";
export var sidebar = "_ac-h";
export var sidebarHeader = "_ac-j";
export var sidebarList = "_ac-i";
export var sidebarListItem = "_ac-k";
export var sidebarSearch = "_ac-l";
export var snoozeDurationWrapper = "_ac-v";
export var statusPillContainer = "_ac-m";
export var tagsContainer = "_ac-n";
export var unselectedMacro = "_ac-o";
export var variable = "_ac-u";
export var wrapper = "_ac-p";