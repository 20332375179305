// extracted by mini-css-extract-plugin
export var disabled = "_b3-6";
export var error = "_b3-7";
export var icon = "_b3-0";
export var inherit = "_b3-a";
export var primary = "_b3-1";
export var quaternary = "_b3-4";
export var quinary = "_b3-5";
export var secondary = "_b3-2";
export var success = "_b3-9";
export var tertiary = "_b3-3";
export var warning = "_b3-8";