// extracted by mini-css-extract-plugin
export var box = "_42-0";
export var check = "_42-1";
export var checkboxButton = "_42-6";
export var checkboxGroup = "_42-5";
export var disabled = "_42-7";
export var ghost = "_42-9";
export var input = "_42-2";
export var label = "_42-4";
export var normal = "_42-8";
export var uncheck = "_42-3";