// extracted by mini-css-extract-plugin
export var grid = "_c-0";
export var span1 = "_c-2";
export var span10 = "_c-b";
export var span10L = "_c-z";
export var span10M = "_c-n";
export var span10Xl = "_c-1b";
export var span11 = "_c-c";
export var span11L = "_c-10";
export var span11M = "_c-o";
export var span11Xl = "_c-1c";
export var span12 = "_c-d";
export var span12L = "_c-11";
export var span12M = "_c-p";
export var span12Xl = "_c-1d";
export var span1L = "_c-q";
export var span1M = "_c-e";
export var span1Xl = "_c-12";
export var span2 = "_c-3";
export var span2L = "_c-r";
export var span2M = "_c-f";
export var span2Xl = "_c-13";
export var span3 = "_c-4";
export var span3L = "_c-s";
export var span3M = "_c-g";
export var span3Xl = "_c-14";
export var span4 = "_c-5";
export var span4L = "_c-t";
export var span4M = "_c-h";
export var span4Xl = "_c-15";
export var span5 = "_c-6";
export var span5L = "_c-u";
export var span5M = "_c-i";
export var span5Xl = "_c-16";
export var span6 = "_c-7";
export var span6L = "_c-v";
export var span6M = "_c-j";
export var span6Xl = "_c-17";
export var span7 = "_c-8";
export var span7L = "_c-w";
export var span7M = "_c-k";
export var span7Xl = "_c-18";
export var span8 = "_c-9";
export var span8L = "_c-x";
export var span8M = "_c-l";
export var span8Xl = "_c-19";
export var span9 = "_c-a";
export var span9L = "_c-y";
export var span9M = "_c-m";
export var span9Xl = "_c-1a";
export var stretch = "_c-1";