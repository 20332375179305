// extracted by mini-css-extract-plugin
export var addButton = "_8q-0";
export var border = "_8q-8";
export var check = "_8q-h";
export var danger = "_8q-6";
export var dropdownContainer = "_8q-1";
export var editModalBody = "_8q-j";
export var footerContainer = "_8q-i";
export var fullWidth = "_8q-a";
export var hide = "_8q-9";
export var icon = "_8q-3";
export var input = "_8q-7";
export var inputFlex = "_8q-b";
export var inputWrapper = "_8q-c";
export var manualFocus = "_8q-5";
export var optionButton = "_8q-4";
export var pillWrapper = "_8q-e";
export var pointer = "_8q-2";
export var showOverflow = "_8q-f";
export var tagsFlex = "_8q-d";
export var xButton = "_8q-g";