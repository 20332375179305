// extracted by mini-css-extract-plugin
export var buttons = "_3o-0";
export var buttonsActive = "_3o-h";
export var cancelConfirm = "_3o-1";
export var closeButton = "_3o-2";
export var column = "_3o-3";
export var container = "_3o-4";
export var detailHeader = "_3o-6";
export var detailTitle = "_3o-7";
export var detailsContent = "_3o-5";
export var header = "_3o-8";
export var headerWithClose = "_3o-9";
export var icon = "_3o-a";
export var infoIcon = "_3o-c";
export var informationContainer = "_3o-b";
export var sideDrawer = "_3o-d";
export var sideDrawerOpen = "_3o-e";
export var stickyFooter = "_3o-f";
export var typeOption = "_3o-g";