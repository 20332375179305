// extracted by mini-css-extract-plugin
export var avatarContainer = "_b2-0";
export var gray = "_b2-f";
export var green = "_b2-c";
export var icon = "_b2-9";
export var image = "_b2-1";
export var lg = "_b2-6";
export var md = "_b2-5";
export var presenceDot = "_b2-b";
export var red = "_b2-d";
export var sm = "_b2-4";
export var status = "_b2-a";
export var xl = "_b2-7";
export var xs = "_b2-3";
export var xxl = "_b2-8";
export var xxs = "_b2-2";
export var yellow = "_b2-e";