// extracted by mini-css-extract-plugin
export var colorSquare = "_d8-0";
export var danger = "_d8-5";
export var lightPink = "_d8-8";
export var neutralBlue = "_d8-7";
export var neutralYellow = "_d8-6";
export var normal = "_d8-1";
export var primary = "_d8-2";
export var primaryLight = "_d8-3";
export var solidBlack = "_d8-g";
export var solidGreen = "_d8-b";
export var solidLightBlue = "_d8-d";
export var solidNavy = "_d8-c";
export var solidOrange = "_d8-f";
export var solidPink = "_d8-e";
export var solidRed = "_d8-9";
export var solidYellow = "_d8-a";
export var success = "_d8-4";