// extracted by mini-css-extract-plugin
export var container = "_32-0";
export var error = "_32-9";
export var label = "_32-5";
export var light = "_32-6";
export var md = "_32-3";
export var sm = "_32-2";
export var thin = "_32-8";
export var thinBold = "_32-7";
export var vertical = "_32-4";
export var xs = "_32-1";