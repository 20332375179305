// extracted by mini-css-extract-plugin
export var actionButtonsContainer = "_d2-0";
export var footer = "_d2-2";
export var infoSection = "_d2-3";
export var infoSectionContainer = "_d2-4";
export var infoSectionTitleRow = "_d2-5";
export var itemDetails = "_d2-6";
export var line = "_d2-7";
export var lineItemImage = "_d2-1";
export var lineItems = "_d2-8";
export var modalContent = "_d2-9";
export var multiLineInfo = "_d2-a";
export var orderName = "_d2-b";
export var strong = "_d2-c";