// extracted by mini-css-extract-plugin
export var button = "_8-0";
export var buttonContent = "_8-a";
export var dontRestrainIconWidth = "_8-7";
export var dropdownButton = "_8-8";
export var iconContainer = "_8-6";
export var lg = "_8-4";
export var md = "_8-3";
export var pending = "_8-b";
export var sm = "_8-2";
export var spinner = "_8-9";
export var xl = "_8-5";
export var xs = "_8-1";