// extracted by mini-css-extract-plugin
export var createdDate = "_a5-1";
export var divider = "_a5-2";
export var headerDivider = "_a5-3";
export var messagesCard = "_a5-0";
export var messagesContainer = "_a5-7";
export var messagesContainerWrapper = "_a5-4";
export var pointerCapturingButtonBoxes = "_a5-a";
export var showLeftPanelButton = "_a5-9";
export var showMoreButton = "_a5-c";
export var showMoreButtonContainer = "_a5-b";
export var summaryTitle = "_a5-6";
export var systemMessageCardWrapper = "_a5-d";
export var topButtons = "_a5-8";
export var withPadding = "_a5-5";