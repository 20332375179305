// extracted by mini-css-extract-plugin
export var actions = "_23-0";
export var actionsContainer = "_23-4";
export var border = "_23-1";
export var childFullHeight = "_23-b";
export var collapse = "_23-2";
export var content = "_23-6";
export var contentChild = "_23-a";
export var fullscreen = "_23-8";
export var hidePadding = "_23-7";
export var noScrollX = "_23-c";
export var noScrollY = "_23-d";
export var optOutTopPadding = "_23-9";
export var pagePadding = "_23-5";
export var profile = "_23-e";
export var profileContainer = "_23-f";
export var profileImage = "_23-g";
export var profileMain = "_23-h";
export var profileSubtitle = "_23-j";
export var profileTitle = "_23-i";
export var tabsWrapper = "_23-3";
export var title = "_23-k";