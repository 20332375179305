// extracted by mini-css-extract-plugin
export var actionButton = "_8k-3e";
export var actionButtonText = "_8k-3d";
export var addIcon = "_8k-13";
export var addItem = "_8k-1f";
export var addNewItemButton = "_8k-8";
export var addNewItemButtonText = "_8k-9";
export var addNewItemIcon = "_8k-a";
export var addressSpace = "_8k-2s";
export var adjustmentBonus = "_8k-1i";
export var adjustmentFee = "_8k-1j";
export var buttonBar = "_8k-2v";
export var buttonContentNew = "_8k-2t";
export var buttonDropdown = "_8k-2x";
export var buttonDropdown2 = "_8k-2y";
export var buttonDropdownContainer = "_8k-2w";
export var card = "_8k-0";
export var changeAddress = "_8k-39";
export var chevron = "_8k-i";
export var clickAwayListener = "_8k-1u";
export var clickable = "_8k-g";
export var container = "_8k-2";
export var coverage = "_8k-25";
export var createLabelsImage = "_8k-2j";
export var deleteIcon = "_8k-2p";
export var deleteIconContainer = "_8k-2o";
export var dropdownContainer = "_8k-3n";
export var dropdownIcon = "_8k-3p";
export var dropdownIconOpen = "_8k-3q";
export var dropdownItem = "_8k-3r";
export var dropdownOption = "_8k-2z";
export var dropdownOptionText = "_8k-2u";
export var dropdownOverlay = "_8k-3t";
export var dropdownText = "_8k-3s";
export var dropdownTrigger = "_8k-3o";
export var editIcon = "_8k-f";
export var editNewItemsLink = "_8k-12";
export var emptyExchangeOrder = "_8k-1m";
export var error = "_8k-h";
export var expandButton = "_8k-3g";
export var expandButtonContainer = "_8k-3h";
export var expanded = "_8k-3i";
export var external = "_8k-24";
export var fraudRiskContainer = "_8k-2f";
export var fullHeight = "_8k-2i";
export var fullWidth = "_8k-2h";
export var greenReturnAdjustment = "_8k-1n";
export var greenReturnImage = "_8k-1r";
export var greenReturnSelectContainer = "_8k-1o";
export var greenReturnSelectTitle = "_8k-1p";
export var greenReturnText = "_8k-1q";
export var headerLayout = "_8k-30";
export var headerSubtitleSmall = "_8k-38";
export var hiddenValues = "_8k-3j";
export var hiddenValuesClosing = "_8k-3l";
export var icon = "_8k-3";
export var item = "_8k-k";
export var itemCard = "_8k-2k";
export var itemCommentImage = "_8k-6";
export var itemCommentImages = "_8k-7";
export var itemComments = "_8k-5";
export var itemHeader = "_8k-q";
export var itemImage = "_8k-l";
export var itemLink = "_8k-21";
export var itemMain = "_8k-m";
export var itemOption = "_8k-v";
export var itemOptions = "_8k-s";
export var itemOptionsText = "_8k-t";
export var itemPrice = "_8k-y";
export var itemPriceContainer = "_8k-16";
export var itemPriceEdit = "_8k-x";
export var itemPriceText = "_8k-17";
export var itemProperties = "_8k-n";
export var itemProperty = "_8k-o";
export var itemPropertyName = "_8k-p";
export var itemSearch = "_8k-1g";
export var itemSubtitle = "_8k-z";
export var itemSubtitleText = "_8k-10";
export var itemSummary = "_8k-18";
export var itemSummaryLine = "_8k-19";
export var itemSummaryValue = "_8k-1a";
export var itemTitle = "_8k-r";
export var itemTitleIcon = "_8k-u";
export var itemType = "_8k-w";
export var items = "_8k-4";
export var linkColor = "_8k-3a";
export var menuTitle = "_8k-1c";
export var modalButton = "_8k-1s";
export var modalContent = "_8k-26";
export var modalFooterRight = "_8k-1t";
export var newAddress = "_8k-j";
export var newItemButtonText = "_8k-e";
export var newItemHeader = "_8k-1k";
export var newItemHeaderTabs = "_8k-1l";
export var newItemShipping = "_8k-b";
export var newItems = "_8k-2r";
export var newItemsSection = "_8k-2q";
export var noTextWrap = "_8k-2l";
export var nonPriceContainer = "_8k-11";
export var optionContent = "_8k-1d";
export var optionTitle = "_8k-1b";
export var orderAndTags = "_8k-3v";
export var orderLink = "_8k-20";
export var orderNames = "_8k-3w";
export var orderNumber = "_8k-3f";
export var orderNumbers = "_8k-32";
export var originalPrice = "_8k-14";
export var paddedModalContent = "_8k-27";
export var priceSummaryTitle = "_8k-1e";
export var processButtons = "_8k-28";
export var product = "_8k-22";
export var productTitle = "_8k-3u";
export var question = "_8k-3c";
export var questions = "_8k-3b";
export var returnTypeModal = "_8k-1v";
export var returnTypeModalButton = "_8k-1y";
export var returnTypeModalHeader = "_8k-1x";
export var right = "_8k-15";
export var rightAligned = "_8k-1w";
export var riskReportIframe = "_8k-2g";
export var shippingText = "_8k-c";
export var shippingTextHeader = "_8k-d";
export var slideDown = "_8k-3k";
export var slideUp = "_8k-3m";
export var status = "_8k-23";
export var subsection = "_8k-2d";
export var subtitle = "_8k-36";
export var subtitleText = "_8k-37";
export var summaryElement = "_8k-1";
export var table = "_8k-1z";
export var tableCell = "_8k-2c";
export var tableDescription = "_8k-2b";
export var tableHeader = "_8k-2a";
export var tableHeaderGroup = "_8k-29";
export var tags = "_8k-34";
export var titleText = "_8k-35";
export var tooltipLink = "_8k-2n";
export var top = "_8k-33";
export var topRow = "_8k-31";
export var trackingNumbers = "_8k-2e";
export var trash = "_8k-2m";
export var voided = "_8k-1h";