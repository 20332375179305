// extracted by mini-css-extract-plugin
export var bubble = "_38-0";
export var container = "_38-3";
export var contrastBorder = "_38-4";
export var greyOut = "_38-2";
export var initialsWrapper = "_38-1";
export var large = "_38-9";
export var medium = "_38-8";
export var mediumSmall = "_38-7";
export var micro = "_38-5";
export var small = "_38-6";
export var tiny = "_38-b";
export var xTiny = "_38-a";