// extracted by mini-css-extract-plugin
export var attachment = "_c4-a";
export var emailContainer = "_c4-4";
export var emailContentPaddedContainer = "_c4-b";
export var emailHeader = "_c4-6";
export var emailInfoIcon = "_c4-9";
export var emailShortenedSummary = "_c4-e";
export var infoDropdown = "_c4-0";
export var infoRowBoundedContent = "_c4-d";
export var internal = "_c4-5";
export var merchant = "_c4-8";
export var messageInputContainer = "_c4-h";
export var normal = "_c4-7";
export var replyCard = "_c4-f";
export var shadowDomWrapper = "_c4-3";
export var subtleButton = "_c4-1";
export var subtleButtonIconContainer = "_c4-2";
export var toggleViewRepliesButton = "_c4-c";
export var wrappingText = "_c4-g";