// extracted by mini-css-extract-plugin
export var acitonButtons = "_6w-0";
export var block = "_6w-1";
export var blockContent = "_6w-7";
export var blockDescription = "_6w-8";
export var blockIcon = "_6w-9";
export var blockTitle = "_6w-6";
export var border = "_6w-a";
export var center = "_6w-k";
export var edge = "_6w-b";
export var edgeLabel = "_6w-c";
export var edgePath = "_6w-d";
export var error = "_6w-e";
export var flowchart = "_6w-f";
export var flowchartContent = "_6w-g";
export var highlighted = "_6w-3";
export var hover = "_6w-4";
export var icon = "_6w-h";
export var left = "_6w-l";
export var navButton = "_6w-i";
export var navButtons = "_6w-j";
export var right = "_6w-m";
export var selected = "_6w-2";
export var small = "_6w-5";