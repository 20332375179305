// extracted by mini-css-extract-plugin
export var container = "_7p-0";
export var content = "_7p-3";
export var customerTitle = "_7p-4";
export var empty = "_7p-5";
export var gray = "_7p-6";
export var header = "_7p-7";
export var info = "_7p-8";
export var loading = "_7p-2";
export var loadingCard = "_7p-9";
export var oneLine = "_7p-a";
export var personName = "_7p-e";
export var pillContainer = "_7p-b";
export var selected = "_7p-1";
export var text = "_7p-c";
export var twoLines = "_7p-d";
export var unread = "_7p-f";
export var unreadIndicator = "_7p-g";