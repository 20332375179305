// extracted by mini-css-extract-plugin
export var alternate = "_2l-h";
export var black = "_2l-3";
export var blue = "_2l-d";
export var blueLight = "_2l-c";
export var brand = "_2l-7";
export var error = "_2l-9";
export var gray = "_2l-6";
export var grayBlue = "_2l-b";
export var iconWrapper = "_2l-4";
export var indigo = "_2l-e";
export var orange = "_2l-g";
export var pink = "_2l-f";
export var primary = "_2l-0";
export var purple = "_2l-8";
export var success = "_2l-5";
export var warning = "_2l-a";
export var white = "_2l-1";
export var whiteSuccess = "_2l-2";