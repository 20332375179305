// extracted by mini-css-extract-plugin
export var chip = "_1r-9";
export var chipLabel = "_1r-a";
export var close = "_1r-7";
export var closeIcon = "_1r-8";
export var error = "_1r-6";
export var infoTooltip = "_1r-2";
export var innerContainer = "_1r-1";
export var input = "_1r-b";
export var medium = "_1r-5";
export var outerContainer = "_1r-0";
export var small = "_1r-4";
export var xSmall = "_1r-3";