// extracted by mini-css-extract-plugin
export var baseCustomerActivityCardArrowRight = "_c8-f";
export var baseCustomerActivityCardArrowRightContainer = "_c8-e";
export var baseCustomerActivityCardContainer = "_c8-c";
export var baseCustomerActivityCardContainerHovered = "_c8-d";
export var conversationActivityDataConversationIconContainer = "_c8-5";
export var customerActivityDataClaimIcon = "_c8-8";
export var customerActivityDataClaimIconContainer = "_c8-7";
export var customerActivityDataConversationIcon = "_c8-6";
export var customerActivityDataConversationIconContainer = "_c8-4";
export var customerActivityDataOrderIcon = "_c8-1";
export var customerActivityDataOrderIconContainer = "_c8-0";
export var customerActivityDataReturnIcon = "_c8-3";
export var customerActivityDataReturnIconContainer = "_c8-2";
export var customerActivityShoppingIconContainer = "_c8-9";
export var messageBubble = "_c8-a";
export var messageBubbleText = "_c8-b";