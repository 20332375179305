// extracted by mini-css-extract-plugin
export var accent = "_24-4";
export var add = "_24-j";
export var addIcon = "_24-k";
export var deleteIcon = "_24-i";
export var disabled = "_24-9";
export var ellipsis = "_24-f";
export var error = "_24-3";
export var form = "_24-5";
export var hideFocus = "_24-2";
export var icon = "_24-7";
export var input = "_24-e";
export var listItem = "_24-h";
export var mediumHeight = "_24-b";
export var noBorder = "_24-8";
export var noRadius = "_24-1";
export var shortHeight = "_24-a";
export var tallHeight = "_24-c";
export var textInput = "_24-0";
export var vertical = "_24-g";
export var veryTallHeight = "_24-d";
export var widget = "_24-6";