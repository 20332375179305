// extracted by mini-css-extract-plugin
export var aboveInput = "_a4-0";
export var actionButton = "_a4-5";
export var actionButtonContainer = "_a4-1";
export var actionIcon = "_a4-6";
export var aiInfoContainer = "_a4-2";
export var attachmentWrapper = "_a4-7";
export var bold = "_a4-3";
export var checkIcon = "_a4-8";
export var clickingRecipientBar = "_a4-11";
export var commentThreadSwitch = "_a4-a";
export var draftImage = "_a4-b";
export var draftImageContainer = "_a4-c";
export var draftImages = "_a4-e";
export var editButton = "_a4-g";
export var editor = "_a4-f";
export var editorContainer = "_a4-y";
export var editorErrorTooltipWrapper = "_a4-9";
export var headerDivider = "_a4-h";
export var icon = "_a4-i";
export var info = "_a4-j";
export var internal = "_a4-n";
export var internalDivider = "_a4-k";
export var internalMessage = "_a4-l";
export var messageInputCard = "_a4-m";
export var messageInputForm = "_a4-o";
export var messageInputFormInternal = "_a4-p";
export var options = "_a4-q";
export var publicMessageCustomer = "_a4-s";
export var publicMessageMerchant = "_a4-r";
export var removeImageButton = "_a4-d";
export var replyButtons = "_a4-t";
export var showSignatureButton = "_a4-u";
export var spinner = "_a4-v";
export var verticalButtonDivider = "_a4-4";
export var visibilitySelector = "_a4-w";
export var warning = "_a4-x";
export var wrappingText = "_a4-z";
export var writingInternalNoteTopBorder = "_a4-10";