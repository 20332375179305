// extracted by mini-css-extract-plugin
export var commandBarModal = "_20-0";
export var commandIcon = "_20-5";
export var menuItem = "_20-8";
export var resultsSection = "_20-7";
export var scrollContainer = "_20-9";
export var searchIcon = "_20-2";
export var searchInput = "_20-3";
export var searchInputContainer = "_20-1";
export var shortcutContainer = "_20-4";
export var shortcutText = "_20-6";