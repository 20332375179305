// extracted by mini-css-extract-plugin
export var actionButton = "_d5-1";
export var actions = "_d5-0";
export var copyableField = "_d5-2";
export var icon = "_d5-3";
export var overflowVisible = "_d5-4";
export var pillWrapper = "_d5-5";
export var productDetails = "_d5-7";
export var productPillsContainer = "_d5-8";
export var productTopDetails = "_d5-9";
export var products = "_d5-6";
export var returnDetails = "_d5-a";
export var strong = "_d5-b";
export var variantQuantityRow = "_d5-c";