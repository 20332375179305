// extracted by mini-css-extract-plugin
export var active = "_cc-b";
export var arrow = "_cc-8";
export var arrowContainer = "_cc-4";
export var container = "_cc-0";
export var disabled = "_cc-5";
export var dot = "_cc-a";
export var dotContainer = "_cc-9";
export var leftArrowContainer = "_cc-6 _cc-4";
export var rightArrowContainer = "_cc-7 _cc-4";
export var slide = "_cc-3";
export var sliderContainer = "_cc-1";
export var sliderOverflow = "_cc-2";