// extracted by mini-css-extract-plugin
export var disabled = "_6-6";
export var hierarchyLinkGray = "_6-a";
export var hierarchyLinkUnderline = "_6-9";
export var hierarchyPrimary = "_6-0";
export var hierarchySecondary = "_6-7";
export var hierarchyTertiary = "_6-8";
export var pressed = "_6-2";
export var themeAction = "_6-1";
export var themeDestructive = "_6-4";
export var themeNormal = "_6-3";
export var themeSuccess = "_6-5";