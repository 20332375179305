// extracted by mini-css-extract-plugin
export var arrows = "_4c-5";
export var decrementer = "_4c-9";
export var disabled = "_4c-3";
export var error = "_4c-1";
export var hiddenText = "_4c-a";
export var incrementer = "_4c-8";
export var input = "_4c-7";
export var inputWrapper = "_4c-0";
export var readonly = "_4c-2";
export var regular = "_4c-6";
export var small = "_4c-4";