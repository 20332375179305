// extracted by mini-css-extract-plugin
export var danger = "_47-6";
export var lightPink = "_47-a";
export var medium = "_47-j";
export var nearSquare = "_47-m";
export var neutralBlue = "_47-8";
export var neutralYellow = "_47-7";
export var noCapitalize = "_47-1";
export var normal = "_47-2";
export var pill = "_47-0";
export var primary = "_47-3";
export var primaryLight = "_47-4";
export var shadow = "_47-n";
export var small = "_47-k";
export var solidBlack = "_47-i";
export var solidGreen = "_47-d";
export var solidLightBlue = "_47-f";
export var solidNavy = "_47-e";
export var solidOrange = "_47-h";
export var solidPink = "_47-g";
export var solidRed = "_47-b";
export var solidWhite = "_47-9";
export var solidYellow = "_47-c";
export var success = "_47-5";
export var xsmall = "_47-l";