// extracted by mini-css-extract-plugin
export var alignBaseline = "_2-8";
export var alignCenter = "_2-4";
export var alignFlexEnd = "_2-6";
export var alignFlexStart = "_2-5";
export var alignSelfBaseline = "_2-d";
export var alignSelfCenter = "_2-9";
export var alignSelfFlexEnd = "_2-b";
export var alignSelfFlexStart = "_2-a";
export var alignSelfStretch = "_2-c";
export var alignStretch = "_2-7";
export var column = "_2-3";
export var flex = "_2-0";
export var justifyCenter = "_2-e";
export var justifyFlexEnd = "_2-g";
export var justifyFlexStart = "_2-f";
export var justifySpaceAround = "_2-i";
export var justifySpaceBetween = "_2-h";
export var justifySpaceEvenly = "_2-j";
export var reverse = "_2-2";
export var row = "_2-1";
export var wrapNowrap = "_2-l";
export var wrapWrap = "_2-k";
export var wrapWrapReverse = "_2-m";