// extracted by mini-css-extract-plugin
export var disabled = "_2g-4";
export var error = "_2g-1";
export var iconWrapper = "_2g-8";
export var infoIconWrapper = "_2g-a _2g-8";
export var input = "_2g-7";
export var inputWrapper = "_2g-0";
export var large = "_2g-6";
export var leadingIconWrapper = "_2g-9 _2g-8";
export var measureBox = "_2g-b";
export var medium = "_2g-5";
export var readonly = "_2g-2";
export var small = "_2g-3";