// extracted by mini-css-extract-plugin
export var brand = "_4y-7";
export var compact = "_4y-5";
export var extraSmall = "_4y-a";
export var icon = "_4y-0";
export var large = "_4y-3";
export var light = "_4y-1";
export var medium = "_4y-4";
export var outline = "_4y-9";
export var primaryDark = "_4y-8";
export var primaryLight = "_4y-6";
export var primaryModern = "_4y-b";
export var success = "_4y-2";