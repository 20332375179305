// extracted by mini-css-extract-plugin
export var centered = "_26-6";
export var flexible = "_26-5";
export var footer = "_26-k";
export var footerBorder = "_26-l";
export var header = "_26-c";
export var headerBorder = "_26-d";
export var headerDefault = "_26-g";
export var headerMedium = "_26-h";
export var headerPadding = "_26-e";
export var iconAndTitle = "_26-f";
export var large = "_26-4";
export var largePadding = "_26-a";
export var medium = "_26-3";
export var mediumPadding = "_26-9";
export var modal = "_26-0";
export var modalContainer = "_26-b";
export var noPadding = "_26-7";
export var skinny = "_26-1";
export var small = "_26-2";
export var smallPadding = "_26-8";
export var subtitle = "_26-j";
export var title = "_26-i";