// extracted by mini-css-extract-plugin
export var actionButton = "_9v-1";
export var actions = "_9v-0";
export var copyableField = "_9v-2";
export var detailColumn = "_9v-3";
export var detailRow = "_9v-4";
export var dropdownTitle = "_9v-5";
export var fulfillmentDetails = "_9v-7";
export var fulfillments = "_9v-6";
export var icon = "_9v-8";
export var imgContainer = "_9v-9";
export var lineItems = "_9v-a";
export var link = "_9v-p";
export var orderCard = "_9v-k";
export var orderCollapseButton = "_9v-l";
export var orderCollapseButtonIcon = "_9v-m";
export var orderDetails = "_9v-b";
export var orderPillsContainer = "_9v-c";
export var pillWrapper = "_9v-d";
export var product = "_9v-e";
export var productDetails = "_9v-g";
export var productTopDetails = "_9v-h";
export var products = "_9v-f";
export var strong = "_9v-i";
export var tightText = "_9v-o";
export var tooltipHidden = "_9v-q";
export var variantQuantityRow = "_9v-j";
export var wrappingText = "_9v-n";