// extracted by mini-css-extract-plugin
export var alignCenter = "_4x-d";
export var bannerShown = "_4x-6";
export var buttonContent = "_4x-0";
export var cardList = "_4x-1";
export var centerContent = "_4x-2";
export var chevron = "_4x-3";
export var closePanelButton = "_4x-k";
export var conversationContextContainer = "_4x-l";
export var conversationDetailContainer = "_4x-4";
export var detailWrapper = "_4x-7";
export var dropdownButton = "_4x-e";
export var dropdownTitle = "_4x-9";
export var fullHeight = "_4x-5";
export var hidden = "_4x-a";
export var icon = "_4x-b";
export var panel = "_4x-i";
export var portalButtonsWrapper = "_4x-c";
export var right = "_4x-j";
export var tableSummaryContainer = "_4x-8";
export var tableSummaryHeader = "_4x-g";
export var threeDotsButton = "_4x-f";
export var wide = "_4x-h";