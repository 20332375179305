// extracted by mini-css-extract-plugin
export var activityItem = "_8l-0";
export var avatarContainer = "_8l-5";
export var commentImage = "_8l-9";
export var deleteButton = "_8l-f";
export var dotContainer = "_8l-b";
export var externalLink = "_8l-3";
export var icon = "_8l-c";
export var input = "_8l-7";
export var inputContainer = "_8l-6";
export var leftContent = "_8l-d";
export var messageBox = "_8l-8";
export var messageInputWrapper = "_8l-a";
export var rightContent = "_8l-e";
export var timelineContainer = "_8l-1";
export var timelineItem = "_8l-2";
export var timelineLine = "_8l-4";