// extracted by mini-css-extract-plugin
export var box = "_3p-1";
export var checked = "_3p-5";
export var descriptionSpacer = "_3p-9";
export var disabled = "_3p-4";
export var icon = "_3p-6";
export var input = "_3p-0";
export var labelSpacer = "_3p-8";
export var medium = "_3p-3";
export var small = "_3p-2";
export var text = "_3p-7";