// extracted by mini-css-extract-plugin
export var animationContainer = "_7x-0";
export var conversationContainer = "_7x-1";
export var detailsContent = "_7x-2";
export var message = "_7x-3";
export var modalContent = "_7x-4";
export var modalFooter = "_7x-6";
export var modalHeader = "_7x-5";
export var name = "_7x-7";
export var optionContainer = "_7x-8";
export var optionTitle = "_7x-9";
export var pillContainer = "_7x-a";
export var selectedConversationsContainer = "_7x-b";
export var selectedOptionContainer = "_7x-c";